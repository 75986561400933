import React from "react";
import { Synaps } from "@synaps-io/verify-sdk";
import axios from "axios";

import "./App.css";

const getKYCSession = () => {
  return axios.post(
    process.env.REACT_APP_INIT_URL,
    {},
    {
      headers: {
        "Api-Key": process.env.REACT_APP_KEY,
      },
    }
  );
};

const getKYBSession = () => {
  return axios.post(
    process.env.REACT_APP_INIT_URL,
    {},
    {
      headers: {
        "x-app-key": process.env.REACT_APP_AUTH_KEY,
      },
    }
  );
};

function App() {
  console.log("service", process.env.REACT_APP_SERVICE);
  const isCorporate = process.env.REACT_APP_SERVICE === "corporate";
  React.useEffect(() => {
    let init = true;
    (isCorporate ? getKYBSession() : getKYCSession())
      .then((res) => {
        console.log(res);
        Synaps.init({
          sessionId: res.data.session_id,
          onFinish: () => {
            alert("Verification finished");
          },
          withFinishButton: true,
          service: process.env.REACT_APP_SERVICE,
          mode: "embed",
        });
      })
      .catch((err) => {
        console.log("Error", err);
      });

    return () => {
      init = false;
    };
  }, []);
  return (
    <div className="App">
      <div style={{ width: "100%", height: "100vh" }} id={"synaps-wrapper"} />
    </div>
  );
}

export default App;
